import {
  Container,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import ApplicationSvg from "@src/assets/icons/Application.svg";
import CargoOwnersSvg from "@src/assets/icons/CargoOwners.svg";
import EfficiencySvg from "@src/assets/icons/Efficiency.svg";
import ProfitSvg from "@src/assets/icons/Profit.svg";
import SecuritySvg from "@src/assets/icons/Security.svg";
import SustainableSvg from "@src/assets/icons/Sustainable.svg";
import GreenDots from "@src/components/GreenDots";

import { styles } from "./CoreValues.styles";
import ValueBlock from "./ValueBlock";

export type CoreValuesProps = WithStyles<typeof styles> & {
  className?: string;
};

class CoreValues extends React.PureComponent<CoreValuesProps> {
  public render() {
    const { classes, className } = this.props;
    return (
      <Container className={cn(className, classes.container)}>
        <GreenDots className={classes.greenDots} />
        <Paper className={classes.paper} elevation={6}>
          <Typography variant="h3" align="center" className={classes.title}>
            <T message="Trucknet" />
          </Typography>
          <Typography variant="h6" align="center" paragraph>
            <T message="Cross company optimization for heavy vehicles" />
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            paragraph
            className={classes.mainSubtitle}>
            <T message="Trucknet provides an AI, BI, ML digital platform enabling companies organizing frequent shipments in all modes,  to save transport costs and to optimize their supply chain processes, by controlling shipments from one single place and with real-time visibility through location and statuses." />
          </Typography>
          <div className={classes.infoContainer}>
            <ValueBlock
              icon={SustainableSvg}
              title={<T message="Sustainable" />}
              className={cn(classes.valueBlock, classes.firstLevelBlock)}>
              <T message="Connecting to Trucknet's automatic platform offers logistics and transportation companies opportunities for saving resources and cutting costs. " />{" "}
              <T message="In addition, the system delivers an online tool for heavy fleet companies to calculate their CO2 emissions." />{" "}
              <T message="The heavy vehicle industry is expected to demonstrate social and environmental responsibility, and to comply with new EU standards for reducing CO2 emissions by 2030." />{" "}
              <T message="Here is where Trucknet adds its unique solution. Quantifying greenhouse gas (GHG) emissions could provide possibilities for companies interested in carbon emission trading." />
            </ValueBlock>
            <ValueBlock
              icon={EfficiencySvg}
              title={<T message="Efficiency" />}
              className={classes.valueBlock}>
              <T message="Trucknet can assist companies use their resources more effectively by selling unused space to other companies with information systems based on location." />{" "}
              <T message="Our BID feature gives the best options available." />
            </ValueBlock>
            <ValueBlock
              icon={ProfitSvg}
              title={<T message="Extra profit" />}
              className={classes.valueBlock}>
              <T message="Companies gain extra profit by selling available capacity (empty space) to other transportation companies, thereby reducing deadhead miles." />
            </ValueBlock>
            <ValueBlock
              icon={SecuritySvg}
              iconClassName={classes.largeIcon}
              title={<T message="Security" />}
              className={classes.valueBlock}>
              <T message="Trucknet's system adheres to the highest privacy standards, guided by the GDPR (General Data Protection Regulation)'s requirements, and stores all data in an encrypted database." />{" "}
              <T message="Security is the primary goal for the development team and the company is ISO 9001 and ISO 27001 certified." />
            </ValueBlock>
            <ValueBlock
              icon={CargoOwnersSvg}
              title={<T message="Trucknet for outsourcing companies​" />}
              className={cn(classes.valueBlock, classes.cargoOwnersBlock)}>
              <span className={classes.caption}>
                (
                <T message="manufacturing companies, retailers, distributors, traders" />
                )
              </span>
              <span className={classes.subtitle}>
                <T message="Benefits" />:
              </span>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <T message="Cost-saving generated by an automatic electronic bid system: save time and get the quickest and best cost option to organize your shipments." />
                </li>
                <li className={classes.listItem}>
                  <T message="Pay less for your shipments by minimizing the cost of empty miles costs, using the automatic perfect match." />
                </li>
                <li className={classes.listItem}>
                  <T message="Reduce all daily tasks of planners and communications (e-mail, phone) by a minimum of 50%." />
                </li>
                <li className={classes.listItem}>
                  <T message="Lower administrative costs from communication (phone), paper flow and printed documentation, through featured centralization of all relevant documents with each shipment, cloud." />
                </li>
                <li className={classes.listItem}>
                  <T message="Increase KPI for delivery to your customers by receiving Real Time Visibility and even sharing with your them, LIVE." />
                </li>
              </ul>
            </ValueBlock>
            <ValueBlock
              icon={ApplicationSvg}
              iconClassName={classes.largeIcon}
              title={<T message="Application" />}
              className={classes.valueBlock}>
              <span className={classes.subtitle}>
                <T message="Driver's app" />:
              </span>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <T message="Professional navigator for the driver." />
                </li>
                <li className={classes.listItem}>
                  <T message="Digital e-CMR document." />
                </li>
              </ul>
              <span className={classes.subtitle}>
                <T message="Trucknet tracker for customers" />:
              </span>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <T message="View the location of the outsourced truck." />
                </li>
                <li className={classes.listItem}>
                  <T message="Digital e-CMR document." />
                </li>
                <li className={classes.listItem}>
                  <T message="POD." />
                </li>
              </ul>
            </ValueBlock>
          </div>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(CoreValues);
